
.loader{
    margin: auto;
    text-align: center;
  }
  .ml-2{
    margin-left: 2px;
  }
  
  .mtl-20{
    margin: 20px 20px 0;
  }
  .mr-10{
    margin-right: 10px;
  }
  .ml-10{
    margin-left: 10px !important;
  }
  .m-0 {
    margin: 0 !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .mt-10 {
    margin-top: 10px !important;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .m-10{
  margin: 10px;
  }
  .m-20{
    margin: 20px;
  }
  .pl-10{
    padding-left: 10px;
  }
  .p-10{
    padding: 10px;
    }
    .p-20{
      padding: 20px;
      }
      .ptlr-20{
        padding: 20px 20px 0;
      }
  .button{
    margin:5px !important;
  }
  .WOlink{
    color:var(--link-color) !important;
    font-weight: bold !important;
    cursor: pointer;
  }
  .link{
    cursor: pointer;  
    color:var(--link-color) !important;
  }
  .detailedListText{
    color: var(--font-color) !important;
  }
  .detailedListText:hover{
    color: var(--font-color-light) !important;  
    font-weight: 600;
  }
  /* Home screen */
  
  .root{
    flex-grow: 1;
    /* background-color: #f2f2f2; */
    width: 100%;
  }
  .homeDiv{
    margin-top: 50px;
    background-color: var(--color-secondary);
  min-height: 100vh
  }

  .paperTop{
    padding: 15px;
    text-align: center;
    color: #4a4a4a;
  }
  .paper{
    border-radius: 10px !important;
    padding: 15px;
    /* text-align: center !important; */
    /* color: #004256; */
    color: var(--color-primary);
    transition-delay: .5s;
   
  }
  .paper:hover {
    /* color: #ffffff; */
    /* background-color: #004256; */
    
    transition: 0.4s ease-in-out;
    background-color: var(--color-prime) !important;
  }
  .paperDisable{
    border-radius: 10px !important;
    padding: 15px;
    /* text-align: center !important; */
    /* color: #004256; */
    background-color:  var(--color-secondary) !important;
    cursor: no-drop;
    /* transition-delay: .5s; */
   
  }
  .boxDiv{
    padding: 0 15px
  }
  .ListDiv{
    /* background-color: #fff; */
    background-color: var(--color-primary);
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
    margin: 20px 10px;  
    border-radius: 10px !important;
  }
  .TopDiv{
    margin-top: 20px !important;
    /* padding-left: 15px !important */
  }
  .lockedTile{
    position: absolute;
  }
  .lockedTileDiv{
    position: relative;
  }
  .nested{
    padding-left: 20px;
    background-color: var(--color-secondary)
  }
  .homeIcon{
    font-size: 58px !important;
    /* color: #333; */
    padding: 7px !important;
  }
  .homeIconDiv{
    margin: auto !important;
    text-align: center !important;
  }
  .homeLable{
    text-align: center !important;
    padding: 15px 24px;
    min-height:20px
  }
  .boxTitle{
    margin-bottom: 7px;
    margin-top: 0;
    font-weight: 600;
    font-size: 1.1em;
     /* color: #004256; */
  }
  .boxText{
    margin-top: 0px;
    font-weight: 400;
    font-size: 1em;
  }
  .TopBoxNumber{
    text-align: center !important;
    font-weight: bolder !important;
    font-size: 1.9em !important;
  }
  .TopBoxTitle{
    margin-bottom: 7px !important;
    margin-top: 0 !important;
    font-weight: 500 !important;
    font-size: 1.0em !important;
    /* color: [theme: themePrimary; default: #0078d7] !important; */
    color:#333
  }
  .TopBoxText{
    margin-top: 0px;
    font-weight: 300;
    /* font-size: 0.9em; */
  }
  .heading{
    
    color:var(--font-color);
    font-size: 1.3em;
    margin-bottom: 10px;
    width: 100%;
  }
  .headingAdmin{
    color: var(--font-color);
    margin-left: 15px;
    margin-bottom: 10px;
    font-size: 1.3em;
    padding-top: 10px;
  }
  .Inprogress{
    color: rgb(60, 81, 88) !important;
    background-color: rgb(144, 195, 212) !important;
  }
  .Assigned{
    color:rgb(96, 94, 31) !important;
    background-color: rgb(201, 198, 95) !important;
  }
  .Onhold{
    color:rgb(149, 55, 4) !important;
    background-color: rgb(251, 142, 84) !important;
  }
  .Complete{
    color:rgb(50, 92, 36) !important;
    background-color: rgb(161, 212, 144) !important;
  }
  .convoGraph, .msgGraph {
    
    min-height: 213px;
  }
  .homeChart{
    border-radius: 4px !important;
  }
  .homeIconBlock{
    width: 60px;
    height: 60px;
    border-radius: 10px;
    /* float: left; */
  }
  
  .homeIconBlockDiv{
    cursor: pointer;
    display: flex;
    background: var(--color-primary);
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
      height: 60px;
      border-radius: 10px;
  }
  .adminBlockIcon{
    
    padding: 18px !important;
    color: #333 !important;
  }
  .homeBlockHeading{
    color: var(--font-color) !important;
    padding-left: 8px;
    padding-top: 20px;
    font-weight: 550;
  }
  .chartHeading{
    color: var(--font-color) !important;
    padding: 10px;
    font-size: 18px;
  }
  
  .servicesBlock{
    background-color: rgb(255, 55, 95);
  }
  
  .kbBlock{
    background-color: rgb(255, 149, 0);
  }
  
  .reportsBlock{
    background-color: rgb(100, 210, 255);
  }
  
  .analyticsBlock{
    background-color: rgb(255, 59, 48);
  }
  .websitesBlock{
    background-color: rgb(104, 238, 110);
  }
  .adminBlocks {
    
    margin: 10px 10px;
  }
  .displayFlex {
    display: flex;
}
.pt-12{
  padding-top:12px;
}


/* Tablet devices */
@media only screen and (min-width: 768px) and (max-width: 1119px){
  body {
    overflow: hidden !important;
  }
    .ui-container {
      font-size: 10px;
    }
  
    .main-content, .page-main-content {
      margin: 10px 5px;
    }

    
    .nav-tab .MuiTypography-root {
      font-size: 10px !important;
    }

    .dx-drawer-opened .dx-drawer-panel-content {
      max-width: 250px !important;
      width: 250px !important;
    }

    .navigation-container .dx-drawer-content{
      height: calc(100vh - 16px) !important;
      width:auto !important;
    }

    /* .layout-page-content{
      height: calc(100vh - 24vh) !important;
    } */

    .dx-drawer-panel-content .dx-overlay-content {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      margin: 24px 0;
      /* height: calc(100vh - 16px) !important; */
  }

  .navigation-container.drawer-closed .dx-overlay-content {
    margin: 32px 0 !important
  }

    tr.dx-adaptive-detail-row .dx-box-flex.dx-box.dx-widget.dx-collection > .dx-item.dx-box-item {
      flex: 1 1 100% !important;
      box-sizing: border-box !important;
    }

    .dashboard-filter {
      padding-top: 0px !important;
    }
  }
  
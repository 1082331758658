/* smaller laptop screens */
@media only screen and (min-width: 1200px) and (max-width: 1535px) {
    body {
      overflow: hidden !important;
    }
    .ui-container {
      font-size: 11px;
    }

    .navigation-container.drawer-closed .dx-drawer-panel-content .nav-tab {
      margin: 8px auto !important;
    }

    .navigation-container .dx-drawer-content {
      width: inherit;
      height: calc(100vh - 16px) !important;
    }

    .dx-drawer-overlap.dx-drawer-left .dx-drawer-wrapper,
    .dx-drawer-overlap.dx-drawer-right .dx-drawer-wrapper {
        overflow-y: hidden !important;
        height: 100vh !important;
    }

    .dx-drawer-panel-content .dx-overlay-content {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      margin: 24px 0;
      height: calc(100vh - 16px) !important;
  }

  .navigation-container.drawer-open .dx-drawer-overlap .dx-drawer-panel-content {
    height: 100%;
    overflow-y: auto;
    }

    .navigation-container.drawer-open .dx-drawer-overlap .dx-drawer-panel-content .dx-overlay-content {
        height: 100%;
        overflow-y: visible;
    }

    .navigation-header {
        padding: 10px 0 !important;
    }

    .navigation-footer {
        position: unset !important;
    }

    .dx-drawer-opened .dx-drawer-panel-content {
        width: 240px !important;
        max-width: 240px !important;
    }

    .dx-drawer-panel-content {
        top: 8px !important;
    }
    
    .main-content, .page-main-content {
      margin: 0 10px 15px;
    }
  
    .dx-drawer-panel-content .nav-tab .MuiListItemText-primary {
      font-size: 11px !important;
    }
  
    .MuiCollapse-root .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary{
      font-size: 11px !important;
    }

    .nav-tab .MuiTypography-root {
      font-size: 10px !important;
    }
    .navigation-header-text {
      font-size: 22px !important;
    }
    /* .layout-page-content{
      height: calc(100vh - 22vh) !important;
    } */

    .login-page.MuiBox-root {
      height: 100vh;
      width: auto;
      margin: 20px;
    }

    .login-form {
      padding: 36px;
    }

    .login-page .worx-text, .login-page .online-text {
      font-size: 26px !important;
    }
  }
  
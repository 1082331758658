@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('./smallDesktop.css');
@import url('./tablet.css');

:root {
  --background-dark: #222a2a;
  --background-light: #F1F1F1;
  --text-color-dark: #22252a;
  --text-color-light: #fff;
  --highlight-color: #03AC3C;
  --box-shadow-color: rgba(205, 205, 212, 0.1);
  --font-color-primary: #1F1F20;
}

@font-face {
  font-family: "Rubik";
  src: local("RubikBold"), url("./fonts/Rubik/Rubik-Bold.ttf") format("truetype");
  font-weight: bold;
}

body {
  padding:0;
  margin:0;
  overflow: hidden; /* Prevent scrolling on the body */
  /* width: 100vw;
  height: 100vh; */
}
  
  body, .layout-page-content, .dx-widget, .MuiTypography-root{ 
  font-family: 'Rubik' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
}

html, body{
  height: 100%;
  font-family: 'Rubik' !important;
  background-color: #222a2a;
  padding: 0;
  margin: 0;
}

.label, .MuiFormLabel-root {
  transform: translate(0, -4px) scale(0.75);
  color: var(--text-color-dark) !important;
  font-family: 'Rubik' !important;
  text-transform: capitalize!important;
  line-height: 20px;
}

.main-content {
  background-color: var(--background-light);
    /* height: 100%; */
  margin-right: 10px;
  border-radius: 32px;
}

.MuiTab-root{
  font-family: 'Rubik' !important;
  font-size: 12px !important;
font-style: normal !important;
font-weight: 600 !important;
line-height: 20px !important;
text-transform: capitalize !important;
}

.MuiTypography-root{
  font-family: 'Rubik' !important;
}

.page-layout .MuiDrawer-root.MuiDrawer-docked, .page-layout .MuiPaper-root.MuiDrawer-paper, *.MuiPaper-root-MuiDrawer-paper{
  background-color: var(--background-dark) !important;
  color: #fff !important;
  overflow: unset !important;
}

.page-layout .MuiDrawer-root.MuiDrawer-docked{
  padding: 0 10px;
}

code, .dx-widget, .dx-checkbox-text, .dx-widget input, .dx-widget-textarea, .dx-texteditor-input{
  font-family: 'Rubik' !important;
    font-style: normal;
    font-weight: 400;
    /* font-size: 12px; */
    line-height: 18px !important;
    text-transform: capitalize;
    /* color: var(--background-dark); !important; */
}

.bold {
  font-weight: 700 !important;
  line-height: 20px;
}

h2{
  font-weight: 700;
    font-size: 24px;
    line-height: normal;
    letter-spacing: -0.72px;
}

.page-layout .MuiDrawer-root.MuiDrawer-docked,
.page-layout .MuiPaper-root.MuiDrawer-paper,
*.MuiPaper-root-MuiDrawer-paper,
.dx-drawer-panel-content {
  background-color: #222a2a !important;
  color: #fff !important;
  overflow: unset !important;
  border-radius: 24px !important;
  padding: 10px;
}

.drawer-open,
.drawer-closed {
  flex: 1;
}

nav.MuiList-root {
  padding-bottom: 20px;
}

.navigation-container.drawer-closed nav.MuiList-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dx-drawer-panel-content .navigation-header-text img {
  height: auto;
  display: block;
  margin: 0 auto;
  flex-shrink: 0;
}

.navigation-footer-text, .navigation-header-text {
  color: hsla(0, 0%, 100%, .5);
  font-family: 'Rubik';
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1.26px;
  line-height: 20px;
  text-transform: uppercase;
}

.navigation-footer .navigation-header-button {
  padding-bottom: 0;
}

.navigation-header {
  padding:20px 0;
}

.dx-drawer-panel-content .navigation-header-button {
  background-color: #222a2a !important;
  color: #fff !important;
  text-transform: uppercase !important;
  font-size: 10px !important;
  border: none !important;
  display: block !important;
  margin: 0 auto !important;
}

.navigation-container .dx-drawer-content {
  background-color: #f0f2f7 !important;
  border-radius: 24px !important;
  height: auto;
  margin: 8px !important;
  /* overflow: auto !important; */
}

.navigation-header-button{
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color-light);
  padding: 10px;
}

.navigation-header-logo {
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.navigation-header-appbar {
  padding:10px 0;
}

.navigation-header-text {
    font-size: 24px;
    font-weight: 400;
    line-height: 20px;
    font-family: 'Rubik';
}

.navigation-container.drawer-closed .nav-tab .MuiCollapse-wrapperInner .nav-tab, .navigation-container.drawer-closed .nav-tab .arrowIcon, .navigation-container.drawer-closed .parentListName {
  display: none !important;
}

.navigation-container.drawer-closed .dx-drawer-panel-content .nav-tab .MuiListItemText-primary{
  display: none !important;
}

.navigation-container.drawer-closed .dx-drawer-panel-content .nav-tab{
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 15px auto;
}

.navigation-footer {
  bottom: 20px;
  color: hsla(0, 0%, 100%, .5);
  display: flex;
  flex-direction: row;
  font-family: 'Rubik';
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  left: 0;
  letter-spacing: 1.26px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.dx-drawer-closed .dx-drawer-panel-content {
  width: 114px !important;
}

.navigation-container.drawer-closed .navigation-header-text img.logoSmall {
  width: 48px !important;
  height: 48px !important;
}

.navigation-container.drawer-open .parentListName {
  display: block !important; 
}

h3{
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--font-color-primary);
}

.dx-texteditor.dx-editor-outlined{
  background: #EEF1F0 !important;
    color: #000000;
    border: none !important;
    box-shadow: 3px -5px 40px 0px var(--box-shadow-color);
    border-radius: 12px !important;
}

nav.MuiList-subheader{
  padding-bottom: 40px;
  /* margin:0 10px; */
}

.appbar-container {
  display: flex;
  align-items: center;
  margin: 16px 0;
  padding: 0 !important;
}

.appBarTitle {
  display: flex;
  align-items: center;
}

.appBarTitle img {
  margin-right: 10px;
}

.appBar-heading-container {
  display: flex;
  flex-direction: column;
}

.appBar-heading-container .appBar-heading {
  margin: 0;
}


.page-layout {
  color: var(--background-dark) !important;
  /* background: #141414; */
  background-color: var(--background-dark);
  display:flex;
  height:100%;
}

.page-main-content{
  background: #F5F5F5;
  background-color: #F5F5F5;
  /* background-color: #eef1f0; */
    border: 1px solid #F5F5F5;
    border-radius: 50px;
    margin: 20px 20px;
    min-height: calc(100vh - 40px) !important;
}

.MuiDrawer-root .MuiPaper-root.MuiPaper-elevation{
  box-shadow: none !important;
  border-radius:none;
}

.MuiPaper-root.MuiPaper-elevation{
  position:relative !important;
  box-shadow: 3px -5px 40px 0px var(--box-shadow-color);
  border-radius: 32px;
  margin: 0px;
}

.MuiDrawer-root.MuiDrawer-modal > .MuiPaper-root.MuiPaper-elevation {
  position:relative !important;
  /* background-color: var(--text-color-light) !important; */
  box-shadow: 3px -5px 40px 0px var(--box-shadow-color);
  background-color: var(--background-dark) !important;
  color: var(--text-color-light) !important;
  border-radius: 32px;
}

.sectionDesktop{
  float:right;
  display: flex;
  align-items: center;
}

.navigation-header .dx-toolbar-items-container {
  background: var(--background-dark) !important;
  color: var(--text-color-light) !important;
  margin-top: 10px !important;
  margin-bottom:20px !important;
}

header.MuiPaper-root.MuiPaper-elevation{
  background-color: transparent !important;
  position:sticky !important;
  top: 0 !important;
  left: auto !important;
  right: 0 !important;
  /* align-items: center;
  justify-content: center; */
  /* padding: 0.5rem 1.5rem; */
  color: var(--background-dark) !important;
  min-height: 64px;
}

.scrolledAppBar .MuiToolbar-root{
  background: var(--text-color-light) !important;
  transition: padding .25s ease-in-out;
  box-shadow: .25s ease-in-out;
  backdrop-filter: .25s ease-in-out;
}

.layout-content-wrapper{
  background-color: #eef1f0;
  border: 1px solid #eef1f0;
  border-radius: 50px;
  margin: 10px 10px 10px 0;
  min-height: calc(100vh - 42px) !important;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.layout-page-content{
  /* flex-grow: 1;
  transition: padding .25s ease-in-out;
  max-width: 100%; */
  padding: 20px 20px;
  overflow: auto;
  height: calc(100vh - 13vh);
  /* height:100vh; */
  /* width:100%; */
}

.MuiPaper-root-MuiCard-root{
  width:100%;
  background-color: #eef1f0 !important;
  color: var(--font-color-primary) !important;
  transition: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.MuiListItem-root{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.ui-container, .results-container{
  background-color: var(--text-color-light);
  color: rgba(76, 78, 100, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 32px;
  box-shadow: 3px -52px 48px 0px var(--box-shadow-color);
  margin: 0px;
  padding: 20px 20px;
}

/* .navigation-header-appbar header.MuiPaper-root.MuiPaper-elevation {
  background-color: #f0f2f7 !important;
} */

/* .results-container{
  margin:10px;
} */

.ui-container .MuiTypography-root.MuiTypography-body1{
  text-transform: uppercase;
    font-weight: 400;
    font-size: 10px;
    padding: 0;
}
.ui-container .MuiTypography-root{
  color: var(--text-color-dark);
    font-family: 'Rubik' !important;
    font-style: normal;
    line-height: 18px !important;
}

/* .MuiTabPanel-root{
  padding: 0.75rem 0 !important;
} */

.actions-container{
  margin: 20px 0;
}

.buttongroups-container{
  display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 10px;
    line-height: 18px;
    flex-direction: row;
}

.buttongroups-container .dx-checkbox-container{
  margin-right: 20px;
}
.results-container .dx-header-row{
  background: var(--highlight-color);
    color: var(--text-color-light);
    line-height: 40px;
}

.results-container .dx-datagrid .dx-header-filter-empty{
  color: var(--text-color-light);
}

.action-buttons{
  float: right !important;
  margin-right:20px;
}

.action-buttons > .MuiButtonBase-root.MuiButton-root.MuiButton-contained:hover{
  background-color: var(--text-color-light) !important;
}
.action-buttons button, .dx-popup-bottom .dx-toolbar-button .dx-button-normal{
    border: 1px solid var(--background-dark);
    margin-left: 10px;
    box-shadow: 3px -5px 40px 0px var(--box-shadow-color);
    border-radius: 12px;
    text-transform: uppercase;
    color: var(--background-dark);
    font-family: Rubik;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    background-color: var(--text-color-light);
    padding: 8px 16px;
    height: 36px;
    gap: 10px;
    flex-shrink: 0;
}

.dx-popup-bottom .dx-toolbar-button .dx-button-normal{
  padding: 0px!important;
}

.dx-datagrid-table a, a, .URLItem{
  color: var(--highlight-color) !important;
  text-decoration:none;
}

.action-buttons .primary-button, .primary-button .dx-widget.dx-button{
  background: var(--highlight-color);
    color: var(--text-color-light) !important;
    margin-left: 10px;
    box-shadow: 3px -5px 40px 0px var(--box-shadow-color);
    border-radius: 12px !important;
    border: none;
}
.primary-button .dx-widget.dx-button {
  margin-left:0px !important;
}
.action-buttons .primary-button:hover {
  border: none !important
}

.dx-popup-bottom .dx-button , .dx-popup-bottom.dx-toolbar .dx-toolbar-item:last-child{
  border-radius: 12px !important;
  box-shadow: 3px -5px 40px 0px var(--box-shadow-color);
}

.dx-popup-title {
  border: none !important;
}

/* .MuiGrid-root{
  margin-top: -0.75rem !important;
    width: calc(100% + 0.75rem) !important;
    margin-left: -0.75rem !important;
} */

/* .MuiGrid-root>.MuiGrid-item{
  padding-left: 0.75rem !important;
  padding-top: 0.75rem !important;
} */

.buttongroups-container .dx-button-has-text .dx-button-content{
  padding: 5px 8px !important;
}
.buttongroups-container .dx-buttongroup-item.dx-button.dx-button-mode-text.dx-item-selected.dx-button-normal, .dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages .dx-selection{
  color: var(--text-color-light) !important;
    background: #2d4040 !important;
    border-radius: 40px;
}

.buttongroups-container .dx-buttongroup{
  margin: 0 8px;
}

/* .layout-page-content > :first-of-type {
  height: 100%;
} */

/* @media (min-width: 1440px) {
  .layout-page-content {
    max-width: 1440px;
  }
}

@media (min-width: 1200px) {
  .layout-page-content {
    max-width: 100%;
  }
} */

#wo-list-header{
  align-items: center;
  display: flex;
  justify-content: center;
  background: transparent;
  padding: 40px 0;
  color: var(--text-color-light);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

a.is-active.sideMenu > li{
  color: var(--text-color-light);
  text-decoration: none;
  /* background-color: #264A4A; */
  border-radius: 12px;
}

.MuiListItemIcon-root{
  color: var(--text-color-light) !important;
  min-width: 35px !important;
}

.navigation-header .dx-button-content .dx-button-text{
  font-size: 20px;
  font-weight: 700;
  font-family: 'Rubik' !important;
}

.dx-drawer-opened .dx-drawer-panel-content {
  max-width: 250px;
  width: 250px !important;
  height: 100% !important;
  overflow: hidden !important;
}

.floatRight{
  float: right;
}

.dx-drawer-panel-content .nav-tab{
  /* border: 1px solid #264A4A; */
  border-radius: 10px;
  /* background-color: var(--background-dark); */
  /* background-color: #0f3636; */
  /* width: 90%; */
  margin: 10px auto;
}

.dx-drawer-panel-content .nav-tab.active{
  background-color: #264A4A;
}

/* .dx-drawer-panel-content .nav-tab{
  text-decoration: none !important;
  text-transform: capitalize;
  font-size: 14px !important;
} */

.dx-drawer-panel-content .nav-tab .MuiListItemText-primary {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px; 
}

.nav-tab.active .NavIcon{
  fill: white !important;
}

.nav-tab .MuiTypography-root {
  font-size: 12px !important;
  text-decoration: none !important;
  text-transform: uppercase;
}

.nav-tab.active .NavIcon g#activeState{
  display:block !important;
}

.nav-tab.active .NavIcon g#defaultState{
  display:none !important;
}

.nav-tab.active a.active.sideMenu.subNavItem{
  padding-left: 0px !important;
}

.nav-tab.active a.active .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
  position: relative;
  font-weight: 700 !important;
  line-height: 20px !important;
  padding-left: 15px;
}

.nav-tab.active a.active .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary::before {
  content: '\2022'; 
  position: absolute;
  left: 0;
  color: var(--text-color-light);
  font-size: 20px;
}

.nav-tab.active > a.active .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary::before {
  content:'';
}

.MuiCollapse-root .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary{
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 400;
  line-height: inherit;
}

.primary-button {
  background: var(--highlight-color) !important;
}

/* .css-10lo27i{
  padding: 6rem !important;
} */

.MuiDrawer-docked{
  flex: 0 0 auto;
  overflow-x: hidden;
  transition: width .25s ease-in-out;
  /* width: 260px; */
  background-color: var(--background-dark) !important;
}

#SignOutIcon, .MuiListItemText-root.signOut > span{
  color: #13DE3D !important;
}

.footerText{
  font-size: 10px;
  color: #7A7F7F;
  text-transform: capitalize;
}

/* .footerContainer{
  position: absolute;
  bottom:0
} */

.footerlogo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 20px 40px;
}

.footerImageText{
  vertical-align: super;
  text-transform: uppercase;
  font-size: 10px;
  padding-left: 5px;
}

/* Tabs css */
.tabs-section, .MuiTabs-root, .MuiTabs-scroller{
  padding: 0px !important;
  overflow-x: auto !important;
  display: flex !important;
  margin-bottom: 20px;
}

.tabs-section .Mui-selected{
  background: var(--background-dark);
    border: 5px solid #ffffff;
    box-shadow: 3px -5px 40px var(--box-shadow-color);
    border-radius: 16px;
    color: var(--text-color-light) !important;
}

.ui-container .MuiTypography-root.MuiTypography-body2 {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
}

.MuiTabs-indicator{
  display:none;
}

.dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox{
  display: inline-block !important;
}

.MuiPaper-root-MuiDrawer-paper{
  background: #161616 !important;
  color: var(--text-color-light) !important;
}

.icon-buttons-list{
  float: right;
    margin: 10px 10px 0 0;
    color: #1e1e1e;
}

.icon-buttons-list button{
  background: #ffffff;
    box-shadow: 3px -5px 40px var(--box-shadow-color);
    border-radius: 20px;
    margin: 0 5px;
}

.MuiButtonBase-root-MuiButton-root{
  background-color: var(--text-color-light) !important;
}

.Tabs-container .MuiTabs-flexContainer{
  background: var(--text-color-light);
    /* padding: 5px; */
    border-radius: 12px;
    display: inline-flex;
}
.schedulerDiv.select-contrainer .dx-daterangebox .dx-dropdowneditor-input-wrapper{
  margin-top: 0px !important;
}

.schedulerDiv.select-contrainer .dx-selectbox-container, .schedulerDiv.select-contrainer .dx-dropdowneditor-input-wrapper{
  margin-top: -5px !important;
}

.schedulerDiv.select-contrainer .dx-texteditor-container{
  background: var(--text-color-light);
  /* padding: 10px 0; */
  border-radius: 12px;
}

.sectionDesktop .button-container{
  display: inline-flex;
  height: 36px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--Jade, var(--highlight-color));
  box-shadow: 3px -5px 40px 0px rgba(205, 205, 212, 0.10);
  color: var(--text-color-light);
}

.sectionDesktop .button-container .CreateWOButton {
  color: var(--text-color-light) !important;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--text-color-light);
  font-family: Rubik;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.ui-paper{
 margin:20px 5px;
}

.ui-paper .assetPaper, .ui-paper-edit .assetPaper {
  cursor: pointer;
}

.ui-paper .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12{
  padding-top:0px;
}

.ui-paper-accordion .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12{
  padding-top: 8px !important;
}

.ui-paper .assetPaper .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1{
  padding:8px 0;
  align-items: center;
}

/* .ui-paper .MuiGrid-root.AssetLableIcon{
  margin-top: -10px;
} */

/* .ui-paper .MuiGrid-root>.MuiGrid-item{
  padding-left: 24px !important;
  padding-top: 24px !important;
} */

.schedulerDiv .dx-state-hover .dx-dropdowneditor-icon{
  background-color: var(--text-color-light) !important;
}

.Tabs-container .MuiGrid-root{
  align-items: center;
  /* margin-bottom: 10px; */
}

.schedulerDiv .MuiButtonBase-root-MuiIconButton-root{
  background: var(--text-color-light);
  color:var(--background-dark);
}

.schedulerDiv .MuiSvgIcon-root{
  color:var(--background-dark);
}

.paperAssetContent{
  margin:0 10px;
}

.popOverContent{
  padding: 15px!important;
}

.workingHoursDiv{
  margin-top: 20px;
    background-color: #f2f2f2!important;
    border-radius: 20px;
    padding: 10px;
}

.workingHoursIcon{
  font-size: 25px;
    margin: 0 10px;
    color: #000!important;
}

.popOverContent .MuiSvgIcon-root{
  color: #333;
    /* font-size: 1.5rem!important; */
}

/* .nav-tab .MuiSvgIcon-root{
  align-self:baseline;
} */

.toggleViewScheduler{
  background-color: #f2f2f2 !important;
    border-radius: 20px;
    padding: 5px;
    height: 35px;
    width: 95px;
    display: flex;
}

.selectedToggleViewSchedulerIconDiv{
  border-radius: 20px;
    height: 35px;
    width: 47px;
    background-color: var(--text-color-light)!important;
    box-shadow: 2px 2px 5px rgba(0,0,0,.5);
}

.toggleViewSchedulerIconDiv{
  border-radius: 20px;
  height: 35px;
  width: 47px;
  cursor: pointer;
  /* padding-top:4px; */
}

.iconInverse{
  transform: rotate(180deg);
}

.toggleViewSchedulerIcon{
  font-size: 18px!important;
    margin: 4px 8px!important;
    color: #000!important;
}

.SchedulerViewSelected{
  box-shadow: 2px 2px 10px rgba(0,0,0,.5);
    border-radius: 7px;
    background-color: var(--text-color-light);
    cursor: no-drop;
}

.SchedulerViewChangeDiv{
  padding: 10px;
    border: 1px solid #ddd;
    width: 50%;
    text-align: center;
    background-color: #f2f2f2;
    cursor: pointer;
}

.SchedulerViewChangeDiv{
  border: 1px solid #f2f2f2!important;
    color: #000!important;
    background-color: #f2f2f2!important;
}

.dx-scrollview-content .dx-list-group{
  border-bottom: 1px solid var(--background-light);;
}

.dx-scrollview-content .dx-list-group-header{
  border:none !important;
}

.dx-scrollview-content .dx-list-group-body {
  border-radius: 16px;
  background: var(--Haze, var(--background-light));
  box-shadow: 3px -5px 40px 0px rgba(205, 205, 212, 0.10);
  margin-bottom:15px;
  /* height:unset !important; */
}

.dx-scrollview-content .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused{
  background-color: transparent !important;
}

.schedulerDiv .MuiButtonBase-root-MuiButton-root{
  color: var(--background-dark) !important;
  font-size: 10px;
}

.ui-paper .danger{
  vertical-align: middle;
  margin-top: -3px;
  cursor: pointer;
}

.star-icon{
  color: var(--text-color-light);
  font-size: 14px !important;
  padding-right: 5px;
}

.closeIcon{
  float:right;
}

.danger.dataGridIcon{
  color:#f55e5e; 
  margin-left:-5px;
}

.dx-button-mode-contained.dx-button-danger{
  color: var(--text-color-light) !important;
}

.workTypeListItemTitle {
  -webkit-box-orient: vertical;
  display: -webkit-box!important;
  height: 25px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  width: 50%;
}

.validatedworkTypeListItem{
  background-color: #6ad38a;
  border: 1px solid #3d7fe9;
  border-radius: 30px;
  color: #1b1a1a;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  /* height: 20px; */
  margin: 0 3px;
  padding: 2px 9px;
  text-transform: none;
}

.workTypeListItem{
  background-color: #f0806c;
  border: 1px solid #3d7fe9;
  border-radius: 30px;
  color: #1b1a1a;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  margin: 0 3px;
  padding: 2px 9px;
  text-transform: none;
}

.dx-datagrid .dx-icon-filter-operation-default:before,  
.dx-datagrid-container .dx-icon-filter-operation-default:before {  
  content: "\f050";  
}  

.nav-tab {
  color: var(--text-color-light) !important;
}

.navigation-menu .dx-button-content .dx-icon.dx-icon-menu, .navigation-menu .dx-button-mode-text .dx-icon, .navigation-menu .dx-button-text{
  color:var(--text-color-light) !important;
}


.navigation-menu{
  padding: 8px;
  display:flex;
  width:100%;
}

.navigation-menu .navigation-header {
  display: inline-flex;
  width: 15%;
  padding:20px 0px;
}

.navigation-menu .navigation-header-appbar {
    display: inline-flex;
    width: 82%;
}


.navigation-menu .dx-toolbar {
  background-color: var(--background-dark) !important;
  color: var(--text-color-light) !important;
}

.navigation-header .dx-button-mode-text .dx-icon{
  color: var(--text-color-light) !important;
  font-size: 22px !important;
  padding-right:10px !important;
}

.navigation-header-content {
  display: flex !important;
  width: 100% !important;
  align-items: center !important;
}

.navigation-header-text {
  display: inline-block;
  margin-left: 10px;
  text-align: left;
}
 
.navigation-header-text-Logo {
  display: inline-block;
}

.online-text {
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  text-transform: lowercase;
}
 
.worx-text {
  color: #fff;
  font-size: 20px;
  text-transform: capitalize;
}

.custom-datagrid-popup {
  min-width: 800px;
  font-family: 'Rubik' !important;
  /* padding:20px; */
}

.custom-datagrid-popup .dx-field-item-label-text {
  font-size: 11px;
  font-weight: 700;
  text-transform: capitalize;
}

.custom-datagrid-popup .dx-item-content.dx-box-item-content{
  margin-bottom: 10px;
}

.modal-popup .MuiPaper-root.MuiPaper-elevation{
  background: var(--background-light);
}


.ui-paper-edit .MuiPaper-root.MuiPaper-elevation.assetPaper {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  padding-left: 20px;
  background: #fff;
  margin-top: 20px;
}


.ui-paper-edit .MuiPaper-root.MuiPaper-elevation.assetPaper .MuiGrid-root.MuiGrid-container {
  align-items: center;
}


.ui-paper-accordion {
  background: #fff !important;
  border-radius: 32px !important;
  margin: 20px 0px 5px 8px !important;
}

.ui-paper-accordion p.selectAssets {
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  text-transform: capitalize;
}

.ui-paper-accordion .MuiAccordionDetails-root .MuiPaper-root.MuiPaper-elevation {
  padding: 10px;
  background: var(--background-light);
}

.MultiAssetIcon {
  vertical-align: middle;
  margin-right: 5px;
  margin-left: -20px;
}

.danger {
  color: #f55e5e;
}

.popContentDiv.viewModal .MuiGrid-root.MuiGrid-container {
  margin:0px !important;
  font-family: 'Rubik' !important;
}

.popContentDiv.viewModal .MuiGrid-root.MuiGrid-item.ms-fontWeight-semibold {
  font-weight: 600 !important;
}

.navigation-container {
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 8px); */
  height: 100vh;
}

.navigation-container.drawer-open .dx-drawer-overlap .dx-drawer-panel-content {
  position: absolute;
    left: 0px !important;
    background: #222a2a !important;
    border-radius: 32px;
    top: 0px;
    overflow: hidden !important;
}

.navigation-container.drawer-open .dx-drawer-overlap .dx-drawer-panel-content .dx-overlay-content{
  width: 240px !important;
  background: #222a2a;
}

.dx-drawer-panel-content .dx-overlay-content {
  background: #222a2a;
}

.navigation-container.drawer-closed .nav-tab.active .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
  display: none !important;
}

/* .navigation-container.drawer-open .navigation-list {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

.navigation-container.drawer-open .navigation-list::-webkit-scrollbar {
  display: none;
}

.tooltip {
  position: absolute;
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 32px;
}

.readMoreLink {
  color: var(--highlight-color) !important;
  font-weight: 700;
  text-transform: capitalize;
  text-decoration: none !important;
}

tr.dx-adaptive-detail-row .dx-box-flex.dx-box.dx-widget.dx-collection {
  /* display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  vertical-align: middle !important;
  align-items: center !important;
  justify-content: center !important; */
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(35%, 1fr)) !important;
  /* gap: 20px !important; */
}

/* tr.dx-adaptive-detail-row .dx-box-flex.dx-box.dx-widget.dx-collection > .dx-item.dx-box-item {
  flex: 1 1 33.33% !important;
  box-sizing: border-box !important;
} */

 .dx-layout-manager .dx-field-item {
  padding-top: 0px !important;
}

/* .dx-datagrid-nowrap, .dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content {
  white-space: normal !important;
} */

.dx-field-item-content.dx-field-item-content-location-right.dx-adaptive-item-text {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
}

.WoCol.assetDet{
  margin-left: 15px;
}

.tabHeading > svg {
  vertical-align: bottom;
  padding-right: 5px;
}

.map-container {
  margin-top:30px;
  border-radius: 32px;
  overflow: hidden;
}

.map-container.dashboard{
  margin-top:0 !important;
}

.status-buttons-container .button-card {
  border-radius: 24px;
  background: var(--Whitewash, #FFF);
  box-shadow: 3px -5px 40px 0px rgba(205, 205, 212, 0.10);
  width: 100%;
}

.button-card:hover {
  border: 1px solid var(--text-color-dark);
  background: #fff !important;
  transition: none;
}

.status-buttons-container .button-content {
  display: flex;
 justify-content: space-between;
 align-items: center;
}

.status-buttons-container .button-text{
  width: 100%;
  margin-right: 20px;
}

.status-buttons-container .button-sub-text{
  margin:0;
}

.status-buttons-container .button-heading {
  margin-bottom: 0;
}

p.no-content, p.alignCenter{
  text-align: center;
  display:block;
  font-family: Rubik;
}

.navigation-container .dx-drawer {
  background-color: #222a2a !important;
}

.dx-drawer {
  height: 100%;
  width: 100%;
}

.dx-drawer-shrink .dx-drawer-wrapper {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
}

.dx-drawer-wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.dx-drawer-shrink .dx-drawer-panel-content {
  flex: 0 0 auto;
  /* height: 100%;
  overflow: hidden; */
  position: relative;
}

.dx-drawer-shrink .dx-drawer-panel-content {
  flex: 0 0 auto;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.dx-drawer-left .dx-drawer-panel-content {
  left: 0;
}

h2.chart-heading {
  font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    margin-top:20px;
}

.status-buttons-container p.button-subtext {
  margin-top: -2px;
  text-align: left;
  margin-left: 15px;
}

.dx-textarea .dx-texteditor-input {
  text-transform: none;
}

.ColorLegendDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ColorLegendItem {
  border-radius: 20px;
  font-weight: 600;
  margin: 5px 5px 10px;
  padding: 5px 10px;
  width: fit-content;
  color: #fff !important;
}

.subHeading {
  padding: 0px 20px;
  width:100%;
  font-size: 14px;
}

.schedulerOptionsButton {
  background: #EEF1F0 !important;
  color: #333 !important;
  border: none !important;
  box-shadow: 3px -5px 40px 0px var(--box-shadow-color) !important;
  border-radius: 12px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  margin-left: 10px !important;
  text-transform: capitalize !important;
}

.list-title {
  font-size: 14px;
  font-weight: 700;
}

/* .dx-editor-outlined.dx-texteditor-with-floating-label{
  margin-top: 0px !important;
} */

.schedulerCardTitle {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 15px;
  margin-bottom: 5px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  font-family: 'Rubik';
}

.ms-fontWeight-semibold{
  font-weight: 500;
}

.dx-scheduler-group-flex-container .dx-scheduler-group-row .dx-scheduler-group-header, .dx-scheduler-header-panel-empty-cell, .dx-scheduler-timeline .dx-scheduler-group-flex-container .dx-scheduler-group-header, .dx-scheduler-work-space-month.dx-scheduler-work-space-grouped[dx-group-column-count="1"] .dx-scheduler-group-table, .dx-scheduler-work-space-month.dx-scheduler-work-space-grouped[dx-group-column-count="1"] .dx-scheduler-sidebar-scrollable, .dx-scheduler-work-space-month.dx-scheduler-work-space-grouped[dx-group-column-count="1"] .dx-scheduler-sidebar-scrollable:before, .dx-scheduler-work-space-vertical-grouped[dx-group-column-count="1"] .dx-scheduler-work-space-vertical-group-table {
  width: 230px !important;
}

.crewTitleColorScheduler{
  border-radius: 12px;
  height: 75px;
  margin-right: 7px;
  padding:0 4px;
  /* margin-top: 1px;
  width: 20px; */
}

.dx-scheduler-group-header{
  display: flex !important;
  align-items: center !important;
  padding: 0 10px !important;
}

.dx-scheduler-group-header .schedulerResourceCell > .MuiGrid-root {
  align-items: center !important;
}

.dx-scheduler-appointment {
  border-radius: 12px !important;
  box-shadow: 3px -5px 40px 0px var(--box-shadow-color) !important;
}

.dx-scheduler-header .dx-toolbar .dx-toolbar-items-container {
  /* background: var(--highlight-color) !important; */
  border-radius: 12px;
}

.dx-scheduler-header-panel-container {
  background: var(--highlight-color) !important;
}

.dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell, .dx-scheduler-header-panel-cell {
  color:#fff !important;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 20px;
  text-transform: capitalize;
}

.dx-item-content.dx-scheduler-appointment-content .schedulerStatusWrapper{
  width:fit-content;
  padding: 5px 10px;
  border-radius: 12px;
  /* background-color: #5c95c5 !important; */
}

.dx-item-content.dx-scheduler-appointment-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dx-item-content.dx-scheduler-appointment-content  .showtime-preview > div{
  margin-bottom:8px;
}

.statusLegendRow {
  margin-top: 0px;
}

.legendWrapper {
  position: absolute;
  /* left: 20px; */
  z-index: 1;
  margin: 10px;
}

.unassignedPivot {
  margin-top: 40px;
}

.legendDiv {
  padding: 2px 6px;
  margin: 7px;
  border-radius: 10px;
  color: #000;
  font-weight: 500;
  font-size: 12px;
  box-shadow: 1px 0px 4px 2px rgba(0, 0, 0, .4);
}

.cardViewChnageWrapper {
  position: absolute;
  right: 20px;
  z-index: 99;
  margin: 10px;
}

.listOnMapWrapper {
  position: absolute;
  right: 20px;
  z-index: 99;
  margin: 10px;
  width: 28vw;
}

.workorderOnMapList {
  height: 68vh;
  overflow: auto;
}

.workorderOnMapListCard {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f2f2f2 !important;
  box-shadow: 1px 0px 4px 2px rgba(0, 0, 0, .4);

}

.listOnMapWrapperNarrow {
  position: relative;
  z-index: 99;
  /* position: absolute;  
  width: 87%;
  bottom: 0; */
}

.workorderOnMapListNarrow {
  /* height: 20vh;
  overflow: auto; */
  height: 20vh;
  overflow: auto;
  top: 49vh;
  position: absolute;
  width: 100%;

}

.workorderOnMapListCardNarrow {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  margin: 10px;
  padding: 10px;
  padding-left: 5px;
  border-radius: 5px;
  background-color: #f2f2f2 !important;
  box-shadow: 1px 0px 4px 2px rgba(0, 0, 0, .4);

}

.ColorLegendDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

.ColorLegendItem {
  width: fit-content;
  margin: 5px 5px 10px;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 600;
  color: var(--font-color-inverse) !important;
}

.tooltipTimeText {
  margin: 7px 0;
}

.tooltipUnassignBtn {
  padding: 5px;
}

.tooltipUnassignBtn:hover {
  font-weight: 600;
  color: rgb(125, 191, 253);
}

.toggleViewScheduler {
  background-color: #f2f2f2 !important;
  border-radius: 20px;
  padding: 5px;
  height: 35px;
  width: 95px;
  display: flex;
}

.toggleViewSchedulerIconDiv {
  border-radius: 20px;
  height: 35px;
  width: 47px;
  cursor: pointer;
}

.toggleViewSchedulerIcon {
  font-size: 18px !important;
  /* margin: 9px 14px !important; */
  color: var(--font-color) !important;
}

.selectedToggleViewSchedulerIconDiv {
  border-radius: 20px;
  height: 35px;
  width: 47px;
  background-color: var(--color-primary) !important;
  box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.5);
}

.iconInverse {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.workingHoursDiv {
  margin-top: 20px;
  background-color: #f2f2f2 !important;
  border-radius: 20px;
  padding: 10px
}

.workingHoursIcon {
  font-size: 25px;
  margin: 0px 10px;
  color: var(--font-color) !important;
}

/* .crewTitleColorScheduler {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 7px;
  margin-top: 1px;
} */

.schedulerStatusWrapper {
  border-radius: 19px;
  padding: 3px 10px;
  width: fit-content;
}

.schedulerResourceCell {
  font-size: 13px;
  margin: 10px;
}

.schedulerResourceCellText {
  font-weight: 500 !important;
  padding-left: 10px;
}

.schedulerResourceCellWO {
  padding-left: 10px;
  color: #7a7a7a;
  font-weight: 400 !important;

}

.renderDateCellStyle,
.renderTimeCellStyle {
  text-align: center;
  width: 100%;
}

.schedulerSettingBtn {
  margin: 10px !important;
  float: right !important;
}


#scheduler {
  margin-left: 270px;
}

.dx-draggable-source {
  opacity: 0.5;
}

.dx-draggable-dragging>* {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 6px 8px rgba(0, 0, 0, 0.2);
}

.legendText {
  margin-left: 38px;
  margin-top: 12px;
  margin-bottom: 0px;
}

.legendColor {
  width: 30px;
  height: 16px;
  float: left;
  margin-bottom: 0px;
}

.scheduler {
  margin: 0px;
}

.mapBtnGroupContainer {
  position: absolute;
  left: 50%;
}

.mapBtnGroup {
  z-index: 10;
  background-color: var(--color-primary) !important;
  display: flex;
  /* box-shadow: 5px 5px 11px rgba(0, 0, 0, 0.5); */
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  /* margin-left: auto;
  margin-right: auto; */
  position: relative;
  left: -50%;
  margin-top: 10px;
}

.mapBtnGroup>div {
  padding: 10px;
  cursor: pointer;
}

.mapBtnAssign {
  margin-right: 2px;
}

.mapBtnUnassign {
  margin-left: 2px;
}

.selectedMapBtn {
  background-color: #fff !important;
  border-radius: 5px;
  box-shadow: 1px 0px 6px 2px rgba(0, 0, 0, .5);
}

.zoomOutMapBtn {
  background-color: #fff !important;
  border-radius: 5px;
  box-shadow: 1px 0px 6px 2px rgba(0, 0, 0, .5);
  margin-left: 2px;
}

.showAddressBtn {
  margin-top: 21px;
  text-align: center;
  border: 1px solid #ddd;
  padding: 7px;
  cursor: pointer;
}

.natureStripLocationMsg {
  font-size: 11px;
  color: rgb(255, 137, 137);
}

.byline {
  color: dimgrey;
  font-size: 14px;
}

.margin0 {
  margin: 0;
}

.user-input-field {
  border-radius: 12px;
  background: var(--Light-Grey, #F0F2F7);
  box-shadow: 3px -5px 40px 0px rgba(205, 205, 212, 0.10);
}

.request-demo-button {
  border-radius: 12px !important;
  border: 1px solid #FFF !important;
  box-shadow: 3px -5px 40px 0px rgba(205, 205, 212, 0.10) !important;
  padding: 8px 16px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px !important;
  flex-shrink: 0 !important;
  color: #FFF !important;
  font-family: 'Rubik' !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  text-transform: uppercase !important;
}

.login-page .worx-text, .login-page .online-text {
  font-size: 30px !important;
}

.login-button:hover {
  background: var(--highlight-color) !important;
}

/* .schedulerSelect {
  margin-top: 10px !important;
} */

.status-buttons-container {
  margin: 10px 0;
}

.chart-container{
  display:flex;
  justify-content: center;
}


.profile-container {
  font-family: 'Rubik' !important;
}

.sectionDesktop .navIcon.profile, .sectionDesktop .navIcon.logout {
  margin-right: 8px;
}

.dx-toast-success {
  background-color: var(--highlight-color) !important;
  color: #fff !important;
}

.dx-pager .dx-pages .dx-info {
  text-transform: none !important;
}

